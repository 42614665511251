import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBarcode, FaSquarespace } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PaymentIcon from '@material-ui/icons/Payment';
import ArbitrageLogo from "./ArbitrageLogo";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@material-ui/core";
import { AiFillDashboard, AiFillHome } from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { RiSettingsLine } from "react-icons/ri";
import NavItem from "./NavItem";
import { MdHistory } from "react-icons/md";
import { RiWallet3Line } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";
import { ListItem, ListItemText, Collapse } from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";

function renderNavItems({ items, pathname, depth = 0, state, setSelectedTab }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            state,
            setSelectedTab,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  state,
  setSelectedTab,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          setSelectedTab,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        tabview={item.tabview}
        key={key}
        title={item.title}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: "#DA8300 !important",
    background: "#DA8300 !important",
  },
  desktopDrawer: {
    top: "76px",
    width: "250px",
    height: "82vh",
    margin: "5px 10px 10px 15px",
    background: theme.palette.background.header,
    overflow: "hidden",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: "50px",
    marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    color: "#FFF !important",
    "& svg": {
      color: "#FFF !important",
      fontSize: "20px",
    },
  },

  activeButton: {
    background: "#F39200",
    color: "black",

    "& svg": {
      color: "#000 !important",
      fontSize: "20px",
    },

    "&:hover": {
      background: "#F39200",
      color: "black",
    },
  },

  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    // bottom: "19px",
    // left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  mainsidebar: {
    "& .MuiListItemText-root": {
      marginLeft: "18px",
    },
    "& p": {
      fontSize: "16px !important",
      fontWeight: "400",
    },
    "& svg": {
      fontSize: "23px",
    },
    "& .MuiTypography-displayBlock": {
      fontSize: "16px !important",
      fontWeight: "300",
    },
  },
}));

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: <AiFillDashboard />,
        href: "/dashboard",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Fuel Fees",
        icon: <PaymentIcon />,
        href: "/fuel-fees",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Exchanges",
        icon: <FaSquarespace />,
        href: "/exchange-first",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Pair Details",
        icon: <FiUserPlus/>,
        href: "/pair-details",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Arbitrage",
        icon: <ArbitrageLogo />,
        href: "/profit-path",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "My Wallet",
        icon: <RiWallet3Line />,
        href: "/my-wallet",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Transaction History",
        icon: <MdHistory />,
        href: "/transactions-history",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Settings",
        icon: <RiSettingsLine />,
        href: "/setting",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Affiliate",
        icon: <FiUserPlus />,
        href: "/affiliate",
        tabview: "Arbitrage",
        clickable: true,
      },
      {
        title: "Subscriptions",
        icon: <BsFillPersonCheckFill />,
        href: "/subscription",
        tabview: "Arbitrage",
        clickable: true,
      },
    ],
  },
];
const sections1 = [
  {
    items: [
      {
        title: "Dashboard",
        icon: <AiFillDashboard />,
        href: "/sniper-dashboard",
        tabview: "Sniper",
        clickable: true,
      },
      {
        title: "Bot settings",
        icon: <RiSettingsLine />,
        href: "/sniper-wallet",
        tabview: "Sniper",
        clickable: true,
      },
      {
        title: "Transaction History",
        icon: <MdHistory />,
        href: "/sniper-transactions",
        tabview: "Sniper",
        clickable: true,
      },
    ],
  },
];
const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState([]);
  const [isLogout, setIsLogout] = useState(false);
  const history = useHistory();
  const renderedSections = tabView === "Arbitrage" ? sections : sections1;

  const handleItemClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2} className={classes.mainsidebar}>
          <Box className="sideMenuBox">
            {renderedSections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
              
                {section?.items?.map((item, j) => {
                  return (
                    <ListItem
                      key={j}
                      button
                      className={`${classes.button} ${
                        window.location.pathname === item.href
                        ? classes.activeButton
                        : ""
                      } `}
                      onClick={() => handleItemClick(item?.href)}
                    >
                      {item.icon}
                      <ListItemText primary={item.title} />
                    </ListItem>
                  );
                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("WalletDetails");
                    sessionStorage.clear();
                    localStorage.clear(); 
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
