import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft } from "src/utils";
import { getDataHandlerWithToken } from "src/apiconfig/service";
import { getDataHandlerAPI } from "src/apiconfig/service";
import ApiConfig from "src/apiconfig/apiconfig";
import io from "socket.io-client";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin(token) {
  const accessToken = window.sessionStorage.getItem("token")
    ? window.sessionStorage.getItem("token")
    : token;
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [multibotStart, setmultibotStart] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [pageTile, setPage] = useState("");
  const [connectedExchangeList, setConnectedExchangeList] = useState();
  const [tokenDetail, setTokenDetail] = useState([]);
  const [updateCountData, setUpdateCountData] = useState([]);
  const [notificationListData, setNotificationListData] = useState([]);
  
  const getProfileData = async () => {
    try {
      const res = await getDataHandlerWithToken("profile");
      if (res.result) {
        setUserData(res.result);
        sessionStorage.setItem("ProfileId",res?.result?._id)

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setIsLogin(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsLogin(false);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      getProfileData();
      notificationListHandler();
    }
  }, [sessionStorage.getItem("token")]);

  const getConnectedExchangeList = async (token) => {
    try {
      const response = await getDataHandlerAPI("connectedExchangeList", token);
      if (response) {
        setConnectedExchangeList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      // getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      getConnectedExchangeList(window.sessionStorage.getItem("token"));
    }
  }, [window.sessionStorage.getItem("token")]);
  

  const notificationListHandler = async () => {
    try {
      setNotificationListData([]);
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.listNotification,

        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setNotificationListData(res?.data?.result);
        setIsLoading(false);
      } else {
        setNotificationListData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);

      setIsLoading(false);

      setNotificationListData([]);
    }
  };

  useEffect(() => {
    if (endTime) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [endTime]);


  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    endTime,
    isLoading,
    timeLeft,
    isLoading,
    tokenDetail,
    setIsLoading,
    connectedExchangeList,
    multibotStart,
    notificationListData,
    setNotificationListData,
    setPage:(props)=>{setPage(props)},
    pageTile,
    // getData: () => {
    //   getData();
    // },
    getConnectedExchangeList: () => {
      getConnectedExchangeList();
    },
    notificationListHandler:()=>{
      notificationListHandler()
    },
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    setIsLogin,
    getProfileData: () => {
      getProfileData();
    },
    checkLogin: (token) => {
      checkLogin(token);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}



