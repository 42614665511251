import React, { useEffect } from 'react';

const TranslateComponent = () => {
  const removepowredBy=document.getElementsByClassName("skiptranslate goog-te-gadget")
    console.log(removepowredBy,"removepowredBy")
useEffect(() => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    function googleTranslateElementInit() {
      new google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          includedLanguages: 'en,hi,mr,gu'
        },
        'google_translate_element'
      );
    }
  `;
  document.body.appendChild(script);

  const translateScript = document.createElement('script');
  translateScript.type = 'text/javascript';
  translateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  document.body.appendChild(translateScript);

  return () => {
    document.body.removeChild(script);
    document.body.removeChild(translateScript);
    const googleTranslateElement = document.getElementById('google_translate_element');
  if (googleTranslateElement) {
    googleTranslateElement.remove();
  }
  };
}, []);

  return (
    <div id="google_translate_element"></div>
  );
};

export default TranslateComponent;
