import React from 'react'
import Image from '../../../views/pages/Home/Image';

const ArbitrageLogo = () => {
  return (
    <div>
        <img src='/images/ArbitrageLogo.svg' atl="im"/>
    </div>
  )
}

export default ArbitrageLogo