import React from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    backgroundImage: `url(${"images/LandingPage/ThanYou.png"})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
    backgroundSize: '100% 100%',
    height:'100vh',
    "@media(max-width:768px)": {
      height:'75vh',
    },
  },
  // mainBox:{
  //   paddingTop:'8rem'
  // },
  text:{
    color:'#FCD535',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '110px',
    fontStyle: 'italic',
    fontWeight: 1000,
    lineHeight: 'normal',
    paddingTop:'6%',
    textShadow:
    "-1px -1px 0 #000000, 4px 4px 0 black, 5px 5px 0 #FFC107, 1px 1px 0 black",
    "@media(max-width:768px)": {
      fontSize: '80px',
      fontWeight: 900,
    },
    "@media(max-width:550px)": {
      fontSize: '50px',
      fontWeight: 700,
      paddingTop:'26%',
    },
    "@media(max-width:450px)": {
      fontSize: '40px',
      fontWeight: 600,
    },

  }
}));
function Image() {
  const classes = useStyles();
  return (
    <Box className={classes.img}>
      <Box className={classes.mainBox}>
        <Typography className={classes.text}>Thank You</Typography>
      </Box>
    </Box>
  );
}

export default Image;
