import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/kyc",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/KYC/Index")),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Register")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/otp-verification",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/OtpVerification")),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
  {
    exact: true,
    guard: true,
    path: "/sniper-dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
  {
    exact: true,
    guard: true,
    path: "/fuel-fees",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/FuelFees")),
  },
  {
    exact: true,
    guard: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange")),
  },
  {
    exact: true,
    guard: true,
    path: "/profit-path",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ProfitPath")),
  },
  {
    exact: true,
    guard: true,
    path: "/pair-details",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/PairDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/exchange-first",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ExchangeMain")),
  },
  {
    exact: true,
    guard: true,
    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Direct")),
  },
  {
    exact: true,
    guard: true,
    path: "/direct-plan",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DirectFirst")),
  },
  {
    exact: true,
    guard: true,
    path: "/intra",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Intra")),
  },
  {
    exact: true,
    guard: true,
    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Triangular/Triangular")),
  },
  {
    exact: true,
    guard: true,
    path: "/transactions-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Transection")),
  },
  {
    exact: true,
    guard: true,
    path: "/token-found",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Tokenfound")),
  },
  

  {
    exact: true,
    guard: true,
    path: "/sniper-transactions",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SniperTransaction")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/other-subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Subscription")),
  },
  {
    exact: true,
    guard: true,
    path: "/subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/ActivatedPlan")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Invite")),
  },
  {
    exact: true,
    guard: true,
    path: "/affiliate",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Affliate")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-tree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ViewTree")),
  },
  {
    exact: true,
    guard: true,
    path: "/invite-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteView")),
  },
  {
    exact: true,
    // guard: true,
    path: "/sniper-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SniperWallet")),
  },

  // {
  //   exact: true,
  //   // guard: true,
  //   path: "/many-wallet",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/ManyWallet")),
  // },
  {
    exact: true,
    guard: true,
    path: "/invite-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/InviteEdit")),
  },
  {
    exact: true,
    guard: true,
    path: "/setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Setting")),
  },
  // {
  //   exact: true,
  //   // guard: true,
  //   path: "/bot-setting",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/BotSniper")),
  // },
  {
    exact: true,
    guard: true,
    path: "/sniper-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/SniperWallet")),
  },

  {
    exact: true,
    guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Mywallet")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-wallet/approve",
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/Approve")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Privacy")),
  },
  {
    exact: true,
    path: "/user-service",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/UserService")),
  },
  {
    exact: true,
    path: "/FAQ",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/FaqSection")),
  },
  {
    exact: true,
    path: "/readMore",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Read")),
  },
  {
    exact: true,
    path: "/term-and-condition",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/Terms")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
