import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Typography,
  Container,
  Avatar,
} from "@material-ui/core";
import Scroll from "react-scroll";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { AuthContext } from "src/context/Auth";
import TranslateComponent from "./TranslateComponent";
import { useLocation } from 'react-router-dom';
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    // padding: " 15px 0px",
    "& .MuiButton-containedPrimary:hover": {
      color: "#fff",
    },
    "& .MuiButton-text": {
      padding: "6px 0px",
    },
    "& .profileButtonDiv": {
      display: "flex",
      gap: "24px",
    },
  },
  logoDrawer: {
    width: "140px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: theme.palette.background.header,
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "80px !important",
    },
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",

      width: "75px",
    },
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "6px 0px",
  },

  menuButton1: {
    paddingLeft: "0",
    display: "block",
    color: "#fff",
    width: "100%",
    // minWidth: "100px",
    whiteSpace: "pre",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  menuMobile1: {
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  headerBox: {
    background: "linear-gradient(90deg, #0B0E11 0%, #FCD535 100%)",
    padding: "14px 56px 14.2px 46px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px",
    },
  },
  ecoButton: {
    whiteSpace: "pre",
    borderRadius: "2px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    border: "1px solid #fff",
  },
  ecoButton1: {
    whiteSpace: "pre",
    borderRadius: "32px",
    background: "#0B0E11",
    boxShadow: "3px 5px 4px 0px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    border: "none",
    height: "43px",
    marginTop: '7px'
  },
  avatarmain: {
    gap: "16px",
  },
  name: {
    color: "#0B0E11",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "100%",
  },
  avatar: {
    "& .MuiAvatar-img": {
      width: "32px",
      height: "32px",
      borderRadius: "100%",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const auth = useContext(AuthContext);
  const {
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    mainHeader,
    ecoButton,
    ecoButton1,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);



  const handleGetStarted = () => {
    const googleTranslateDropdown = document.querySelector('.goog-te-combo');
    if (googleTranslateDropdown) {
      googleTranslateDropdown.value = 'en';
      // Trigger change event to simulate user interaction and ensure the change takes effect
      const event = new Event('change', { bubbles: true });
      googleTranslateDropdown.dispatchEvent(event);
      console.log("Google Translate API loaded and language set to English");
    } else {
      console.log("Google Translate dropdown not found");
    }
    history.push('/login');
  };
  



  const userData = auth.userData;
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={{ gap: "48px" }}
          >
            <ScrollLink
              onClick={() =>history.push("/")}
              smooth={true}
              duration={500}
              to="feature"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                Features
              </Typography>
            </ScrollLink>
            {/* <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="work"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                How it works
              </Typography>
            </ScrollLink> */}
            {/* <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="price"
              style={{ cursor: "pointer" }}
            >
              <Typography variant="h6" className={menuButton1}>
                Pricing
              </Typography>
            </ScrollLink> */}

            <a
              href="images/WebInfo.pdf"
              download="WebInfo.pdf"
              target="_blank"
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
              >
                Download
              </Typography>
            </a>
          </Box>
        </Box>
        <Box className="profileButtonDiv">
          {auth.userLoggedIn && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.avatarmain}
              onClick={() => history.push("/profile")}
              style={{ cursor: "pointer" }}
            >
              <Avatar
                src={
                  userData?.profilePic
                    ? userData?.profilePic
                    : "/broken-image.jpg"
                }
                className={classes.avatar}
              />
              <Typography variant="h6" className={classes.name}>
                {userData?.firstName ? userData?.firstName : ""}{" "}
                {userData?.lastName ? userData?.lastName : ""}
              </Typography>
              <Box
                style={{ width: 1, height: 24, background: "#56CCF2" }}
              ></Box>
            </Box>
          )}
          <Box>
            {auth.userLoggedIn ? (
              <Button
                variant="outlined"
                className={ecoButton1}
                to="/my-wallet"
                color="primary"
                fullWidth
                component={Link}
              >
                Dashboard
              </Button>
            ) : (
              <Button
                variant="outlined"
                className={ecoButton1}
                onClick={handleGetStarted}
                color="primary"
                fullWidth
              >
                Get Started
              </Button>
            )}{" "}
          </Box>

          <Box className={classes.divDropdown}>
            <TranslateComponent style={{cursor:'pointer'}}/>
            {/* <div id="google_translate_element"></div> */}
          </Box>

        </Box>

      </Toolbar>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {femmecubatorLogo}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {auth.userLoggedIn && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.avatarmain}
                  onClick={() => history.push("/profile")}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    src={
                      userData?.profilePic
                        ? userData?.profilePic
                        : "/broken-image.jpg"
                    }
                    className={classes.avatar}
                  />
                  <Typography variant="h6">
                    {userData?.firstName ? userData?.firstName : ""}{" "}
                    {userData?.lastName ? userData?.lastName : ""}
                  </Typography>
                </Box>
              )}
            </div>

            <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="feature"
              style={{ cursor: "pointer", textAlign: "left" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                Features
              </Typography>
            </ScrollLink>
            {/* <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="work"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                How it works
              </Typography>
            </ScrollLink> */}
            {/* <ScrollLink
              onClick={() => history.push("/")}
              smooth={true}
              duration={500}
              to="price"
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ textAlign: "left", marginTop: "10px" }}
              >
                Pricing
              </Typography>
            </ScrollLink> */}
            <a
              href="images/WebInfo.pdf"
              download="WebInfo.pdf"
              target="_blank"
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="h6"
                className={menuButton1}
                style={{ margin: '10px 0px' }}
              >
                Download
              </Typography>
            </a>
            <Box style={{ marginTop: "10px" }}>
              {auth.userLoggedIn ? (
                <Button
                  variant="outlined"
                  className={ecoButton}
                  to="/my-wallet"
                  color="primary"
                  fullWidth
                  component={Link}
                >
                  Dashboard
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={ecoButton}
                  onClick={handleGetStarted}
                  color="primary"
                  fullWidth
                >
                  Get Started
                </Button>
              )}
            </Box>
            <Box >
              <TranslateComponent style={{cursor:'pointer'}}/>
              {/* <div id="google_translate_element"></div> */}
            </Box>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#F39200", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  return (
    <>
      <AppBar
        className={classes.headerBox}
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ border: "none" }}
      >
        <Container maxWidth={false}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
