import React, { useContext, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import { BiBell } from "react-icons/bi";
import { toast } from "react-toastify";

import axios from 'axios';
import ApiConfig from "src/apiconfig/apiconfig";


const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "7px 30px 7px 30px",

    background: theme.palette.background.header,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 20px",
    },
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
      "@media(max-width:767px)": {
        fontSize: "18px",
      },
    },
    "& .leftBox": {
      width: "100%",
      maxWidth: "306px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "150px",
      },
      "& img": {
        width: "100%",
        maxWidth: "110px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "80px",
          paddingLeft: "0 !important",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "@media(max-width:483px)": {
          maxWidth: "58px",
        },
      },
    },
  },
  tabButton: {
    padding: "10.5px 20px",
    background: "transparent",
    color: "#F39200",
    "@media(max-width:483px)": {
      padding: "5px 9px",
    },
  },
  activeTabButton: {
    background: "#F39200",
    color: "#0C0C0C",
  },
  mainboxtab: {
    // borderRadius: '30px',
    border: "1px solid #F39200",
    display: "flex",
    alignItems: "center",
    background: "transparent",
    color: "#F39200",
    marginRight: "10px",
    cursor: "pointer",
    borderRadius: "32px",
    overflow: "hidden",
    minWidth: "200px",
    "@media(max-width:483px)": {
      minWidth: "124px",
      fontSize: "11px",
    },
  },
  iconmain: {
    "& .MuiIconButton-root": {
      "@media(max-width:767px)": {
        padding: "5px !important",
      },

    },
  },
  avatarmain: {
    marginLeft: "16px",

    "@media(max-width:767px)": {
      marginLeft: "6px",
    },
  },
  notification: {
    borderRadius: "50%",
    fontSize: "15px",
    position: "absolute",
    top: "2px",
    right: "8px",
    color: "#F39200",
    fontWeight: "bolder",
    "@media(max-width:767px)": {
      top: "-4px",
      right: "4px",
      fontSize: "12px",
    },
  },
}));

const TopBar = ({
  selectedTab,
  onTabChange,
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const userData = auth.userData;
  const newFunction1 = () => {
    history.push("/dashboard");
    onTabChange("Arbitrage");
  };

  const newFunction = () => {
    history.push("/sniper-dashboard");
    onTabChange("Sniper");
  };

  const markNotificationsAsRead = async () => {

    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.readNotification,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        auth.notificationListHandler();
        history.push("/notification");
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      }
    }
  };
  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={{ boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)" }}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#F39200", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box className="leftBox">
                <Link to="/dashboard">
                  <Logo width="125" />
                </Link>
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid item lg={4} md={6} sm={5}>
              </Grid>
            </Hidden>
            <Grid item lg={5} md={3} sm={3} xs={6}>
              <Box className="displayEnd" style={{ justifyContent: "end" }}>
                <Box className={classes.iconmain}>
                  <IconButton onClick={markNotificationsAsRead} >
                    <BiBell />
                    {auth?.notificationListData?.length > 0 && (
                      <span className={classes.notification}>
                        {auth?.notificationListData?.filter((element) => element.isRead === false)?.length > 0 && (
                          auth?.notificationListData?.filter((element) => element.isRead === false)?.length
                        )}
                      </span>
                    )}


                  </IconButton>
                </Box>

                <Box className={classes.mainboxtab}>
                  <Box
                    onClick={() => {
                      newFunction1();
                    }}
                    className={`${classes.tabButton} ${selectedTab === "Arbitrage" ? classes.activeTabButton : ""
                      }`}
                  >
                    Arbitrage
                  </Box>

                  <Box
                    onClick={() => {
                      newFunction();
                    }}
                    // disabled
                    className={`${classes.tabButton} ${selectedTab === "Sniper" ? classes.activeTabButton : ""
                      }`}
                  >
                    Sniper
                  </Box>
                </Box>

                <Box
                  className={classes.avatarmain}
                  onClick={() => history.push("/profile")}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    src={
                      userData?.profilePic
                        ? userData?.profilePic
                        : "/broken-image.jpg"
                    }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();

  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
        />
      </Box>
    </>
  );
}
